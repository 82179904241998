/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings').controller('UserDetailController', ['$scope', '$rootScope', '$state', '$stateParams', '$mdDialog', 'UserResourceFactory', 'AgencyResourceFactory', 'AdvertiserResourceFactory', 'EnumService', 'PasswordResourceFactory', 'loginService', 'NotificationService', 'addelivery.event.constants', '$timeout', 'uiGridConstants', 'SecurityRoleResourceFactory', 'DestinationListResourceFactory', function ($scope, $rootScope, $state, $stateParams, $mdDialog, UserResourceFactory, AgencyResourceFactory, AdvertiserResourceFactory, EnumService, PasswordResourceFactory, loginService, NotificationService, EventConstants, $timeout, uiGridConstants, SecurityRoleResourceFactory, DestinationListResourceFactory) {
    /* PRIVATE : DATA */
    var events = {
      provider: ['XferClientStatusChangeEvent', 'MetadataUploadValidationFailureEvent', 'OrderCancellationEvent', 'OrderCompletionEvent', 'OrderFailureEvent', 'OrderReceivedAndProcessingEvent', 'OrderReceivedAndWaitingEvent', 'SpotCreationFailureEvent', 'SpotImportLongDurationEvent', 'SpotImportBatonFailureEvent', 'SpotImportCenterAudioFailureEvent', 'SpotImportCompletionEvent', 'SpotImportRejectionEvent', 'SpotImportQCRejectionEvent', 'SpotImportTranscodeFailureEvent', 'SpotUploadFailureEvent', 'SpotImportQCRequestEvent', 'OrderDeliverySpotOnlyEvent', 'OrderDeliveryTrafficOnlyEvent'],
      receiver: ['XferClientStatusChangeEvent', 'OrderItemDownloadAvailableEvent', 'OrderItemCompletionEvent', 'OrderItemParkedEvent', 'PromoOrderItemDownloadAvailableEvent', 'OrderItemCompletionWithTrafficEvent'],
      psvendor: ['XferClientStatusChangeEvent', 'SpotProdSvcsReadyEvent', 'SpotImportBatonFailureEvent', 'SpotImportCompletionEvent', 'SpotImportRejectionEvent', 'SpotImportQCRejectionEvent', 'SpotUploadFailureEvent'],
      qcvendor: ['SpotImportQCRequestEvent']
    };
    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/

    var vm = this;
    var sessionData = loginService.getSessionData();
    /* BINDABLE : DATA */

    vm.user = {};
    vm.originalUser = {};
    vm.agencies = [];
    vm.advertisers = [];
    vm.enableFilters = false;
    vm.states = [];
    vm.provinces = [];
    vm.countries = [];
    vm.jobsClassifications = [];
    vm.passwordResetResource = new PasswordResourceFactory();
    vm.currentUser = loginService.lastLoginName();
    vm.showChangeButton = false;
    vm.repeatPassword = '';
    vm.gridApi = null;
    vm.trafficDelivery = false;
    vm.review = false;
    vm.delivery = false;
    vm.promo = false;
    vm.trafficOnly = false;
    vm.allRoles = {};
    vm.userRoles = {};
    vm.adminFlag = loginService.isAdmin();
    vm.destinationsLists = []; // So we can check selectAll values

    vm.notifications = {};
    vm.isDisabled = false;
    vm.disableApi = false;
    vm.accountType = loginService.getSessionData().accountType.replace(/_/g, '');
    vm.ogDestContact = false;
    vm.destinationsGridOptions = {
      enableColumnMenus: false,
      appScopeProvider: vm,
      enableSorting: false,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      columnDefs: [{
        name: 'callLetter',
        field: 'Destination.alias',
        displayName: 'call letter/syscode',
        cellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell" ng-dblclick="grid.appScope.openDestinationDialog(row.entity)">{{row.entity.Destination.alias}}</div>',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC]
      }, {
        name: 'trafficDelivery',
        field: 'notifyWhenDeliveryReceived',
        displayName: 'All Deliveries?',
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>All Deliveries?</span><br /><md-checkbox ng-model="grid.appScope.trafficDelivery" ng-change="grid.appScope.selectAllColumnValues(\'trafficDelivery\')" class="grid-header-checkbox md-primary" aria-label="All Deliveries Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell" ng-dblclick="grid.appScope.openDestinationDialog(row.entity)"><md-checkbox ng-model="row.entity.notifyWhenDeliveryReceived" class="md-primary" aria-label="Item Traffic Delivery Checkbox" ng-change="grid.appScope.preventOther(row.entity, \'notifyWhenDeliveryReceived\', \'notifyWhenDeliveryCompletionOnlyWithTraffic\')"></md-checkbox></div>'
      }, {
        name: 'trafficOnly',
        field: 'notifyWhenDeliveryCompletionOnlyWithTraffic',
        displayName: 'Only Deliveries with Traffic?',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>Only Deliveries with Traffic?</span><br /><md-checkbox ng-model="grid.appScope.trafficOnly" ng-change="grid.appScope.selectAllColumnValues(\'trafficOnly\')" class="grid-header-checkbox md-primary" aria-label="Only Deliveries with Traffic Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell" ng-dblclick="grid.appScope.openDestinationDialog(row.entity)"><md-checkbox ng-model="row.entity.notifyWhenDeliveryCompletionOnlyWithTraffic" class="md-primary" aria-label="Item Promo Delivery Checkbox" ng-change="grid.appScope.preventOther(row.entity, \'notifyWhenDeliveryCompletionOnlyWithTraffic\', \'notifyWhenDeliveryReceived\')"></md-checkbox></div>'
      }, {
        name: 'review',
        field: 'notifyWhenOrderItemReadyForTrafficReview',
        displayName: 'traffic review?',
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>traffic review?</span><br /><md-checkbox ng-model="grid.appScope.review" ng-change="grid.appScope.selectAllColumnValues(\'review\')" class="grid-header-checkbox md-primary" aria-label="Traffic Review Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell" ng-dblclick="grid.appScope.openDestinationDialog(row.entity)"><md-checkbox ng-model="row.entity.notifyWhenOrderItemReadyForTrafficReview" class="md-primary" aria-label="Item Traffic Review Checkbox"></md-checkbox></div>'
      }, {
        name: 'delivery',
        field: 'notifyWhenOrderItemAvailableForDownload',
        displayName: 'delivery available?',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>delivery available?</span><br /><md-checkbox ng-model="grid.appScope.delivery" ng-change="grid.appScope.selectAllColumnValues(\'delivery\')" class="grid-header-checkbox md-primary" aria-label="Delivery Available Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell" ng-dblclick="grid.appScope.openDestinationDialog(row.entity)"><md-checkbox ng-model="row.entity.notifyWhenOrderItemAvailableForDownload" class="md-primary" aria-label="Item Delivery Available Checkbox"></md-checkbox></div>'
      }, {
        name: 'promo',
        field: 'notifyWhenPromoOrderItemAvailableForDownload',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>promo delivery available?</span><br /><md-checkbox ng-model="grid.appScope.promo" ng-change="grid.appScope.selectAllColumnValues(\'promo\')" class="grid-header-checkbox md-primary" aria-label="Promo Delivery Available Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell" ng-dblclick="grid.appScope.openDestinationDialog(row.entity)"><md-checkbox ng-model="row.entity.notifyWhenPromoOrderItemAvailableForDownload" class="md-primary" aria-label="Item Promo Delivery Checkbox"></md-checkbox></div>'
      }]
    };

    vm.destinationsGridOptions.onRegisterApi = function (gridApi) {
      vm.gridApi = gridApi;
    };

    vm.userNotificationGridOptions = {
      enableColumnMenus: false,
      appScopeProvider: vm,
      enableSorting: false,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      columnDefs: [{
        name: 'event',
        field: 'label',
        displayName: 'event',
        defaultSort: {
          direction: uiGridConstants.ASC,
          priority: 0
        }
      }, {
        name: 'email',
        field: 'emailFlag',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>email?</span><br /><md-checkbox ng-model="grid.appScope.notifications.emailFlag" ng-change="grid.appScope.toggleUserNotifications(\'emailFlag\')" class="grid-header-checkbox md-primary" aria-label="Email Notification Header Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell"><md-checkbox ng-model="row.entity.emailFlag" class="md-primary" aria-label="Email Notification Delivery"></md-checkbox></div>'
      }, {
        name: 'popup',
        field: 'growlFlag',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: '<div class="ui-grid-cell-contents wrapped-header-cell"><span>popup?</span><br /><md-checkbox ng-model="grid.appScope.notifications.growlFlag" ng-change="grid.appScope.toggleUserNotifications(\'growlFlag\')" class="grid-header-checkbox md-primary" aria-label="Popup Notification Header Checkbox"></md-checkbox></div>',
        cellTemplate: '<div class="ui-grid-cell-contents centered-child-cell"><md-checkbox ng-model="row.entity.growlFlag" class="md-primary" aria-label="Popup Notification Delivery"></md-checkbox></div>'
      }]
    };
    /* BINDABLE : METHODS*/

    vm.checkPasswordRepeat = checkPasswordRepeat;
    vm.deleteDestination = deleteDestination;
    vm.disableDeleteButton = disableDeleteButton;
    vm.disableRole = disableRole;
    vm.isNewUser = isNewUser;
    vm.openDestinationDialog = openDestinationDialog;
    vm.openFiltersDialog = openFiltersDialog;
    vm.openPasswordDialog = openPasswordDialog;
    vm.resetPassword = resetPassword;
    vm.selectAllColumnValues = selectAllColumnValues;
    vm.showAdminRoles = showAdminRoles;
    vm.showChangePassword = showChangePassword;
    vm.toggleUserNotifications = toggleUserNotifications;
    vm.isDestContact = isDestContact;
    vm.preventOther = preventOther;
    vm.disableSave = disableSave;
    vm.saveUser = saveUser;
    vm.saveAndAdd = saveAndAdd;
    vm.resetUser = resetUser;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function disableSave() {
      if ($scope.userDetailForm) {
        if ($stateParams.id == 'new' && ($scope.userDetailForm.$invalid || $scope.userDetailForm.password && $scope.userDetailForm.repeatPassword && $scope.userDetailForm.password.$viewValue !== $scope.userDetailForm.repeatPassword.$viewValue)) {
          return true;
        } else if ($scope.userDetailForm.$invalid || !$scope.userDetailForm.$dirty) {
          return true;
        }

        return false;
      }
    }

    function checkPasswordRepeat() {
      if (vm.user.password !== vm.repeatPassword) {
        $scope.userDetailForm.repeatPassword.$invalid = true;
      } else {
        $scope.userDetailForm.repeatPassword.$invalid = false;
      }
    }

    function deleteDestination() {
      var rowToDelete = vm.gridApi.selection.getSelectedRows();

      for (var i = 0; i < vm.user.Contacts.length; i++) {
        if (rowToDelete[0].Destination.id === vm.user.Contacts[i].Destination.id) {
          vm.user.Contacts.splice(i, 1);
          vm.gridApi.core.refresh();
          $scope.userDetailForm.$setDirty();
          return;
        }
      }
    }

    function disableDeleteButton() {
      var seletedRow = vm.gridApi && vm.gridApi.selection ? vm.gridApi.selection.getSelectedRows() : [];

      if (seletedRow.length > 0) {
        return false;
      } else {
        return true;
      }
    }

    function disableRole(role) {
      if (role.roleName === 'Destination Contact') {
        vm.disableApi = !!role.selected;

        for (var i = 0; i < vm.userRoles.receiverRoles.length; i++) {
          if (vm.userRoles.receiverRoles[i].roleName === 'Manage Account' && vm.userRoles.receiverRoles[i].selected || vm.userRoles.acceptApiRole.selected) {
            return true;
          }
        }
      } else if (role.roleName === 'Manage Account' || role.roleName === 'Access API') {
        for (var _i = 0; _i < vm.userRoles.receiverRoles.length; _i++) {
          if (vm.userRoles.receiverRoles[_i].roleName === 'Destination Contact' && vm.userRoles.receiverRoles[_i].selected) {
            return true;
          }
        }
      }

      return false;
    }

    function isNewUser() {
      return $stateParams.id == 'new';
    }

    function openDestinationDialog(selectedRow) {
      var dialog = $mdDialog.show({
        controller: 'DestinationsDialogController',
        controllerAs: 'vm',
        template: require('./destinationsNotificationsDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          selectedRow: selectedRow,
          contacts: vm.user.Contacts
        }
      });
      dialog.then(function (response) {
        $scope.userDetailForm.$setDirty();

        if (vm.user.Contacts) {
          for (var i = 0; i < vm.user.Contacts.length; i++) {
            if (response.multiCallLetter.id === vm.user.Contacts[i].Destination.id) {
              vm.user.Contacts[i].notifyWhenDeliveryReceived = response.flags.notifyWhenDeliveryReceived ? response.flags.notifyWhenDeliveryReceived : false;
              vm.user.Contacts[i].notifyWhenDeliveryCompletionOnlyWithTraffic = response.flags.notifyWhenDeliveryCompletionOnlyWithTraffic ? response.flags.notifyWhenDeliveryCompletionOnlyWithTraffic : false;
              vm.user.Contacts[i].notifyWhenOrderItemReadyForTrafficReview = response.flags.notifyWhenOrderItemReadyForTrafficReview;
              vm.user.Contacts[i].notifyWhenOrderItemAvailableForDownload = response.flags.notifyWhenOrderItemAvailableForDownload;
              vm.user.Contacts[i].notifyWhenPromoOrderItemAvailableForDownload = response.flags.notifyWhenPromoOrderItemAvailableForDownload;
              return;
            }
          }
        }

        vm.destinationsGridOptions.data.push({
          Destination: {
            id: response.multiCallLetter.id,
            alias: response.multiCallLetter.alias
          },
          notifyWhenDeliveryReceived: response.flags.notifyWhenDeliveryReceived ? response.flags.notifyWhenDeliveryReceived : false,
          notifyWhenDeliveryCompletionOnlyWithTraffic: response.flags.notifyWhenDeliveryCompletionOnlyWithTraffic ? response.flags.notifyWhenDeliveryCompletionOnlyWithTraffic : false,
          notifyWhenOrderItemReadyForTrafficReview: response.flags.notifyWhenOrderItemReadyForTrafficReview,
          notifyWhenOrderItemAvailableForDownload: response.flags.notifyWhenOrderItemAvailableForDownload,
          notifyWhenPromoOrderItemAvailableForDownload: response.flags.notifyWhenPromoOrderItemAvailableForDownload
        });
        vm.user.Contacts = vm.destinationsGridOptions.data;
      }, function () {});
    }

    function openFiltersDialog() {
      $mdDialog.show({
        controller: 'ManageFiltersController',
        controllerAs: 'vm',
        template: require('./managefiltersdialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        resolve: {
          agencies: function agencies() {
            return vm.agencies;
          },
          advertisers: function advertisers() {
            return vm.advertisers;
          }
        }
      }).then(function (selected) {
        $scope.userDetailForm.$setDirty();
        vm.user.PermissionContexts = vm.user.PermissionContexts ? vm.user.PermissionContexts : [];
        angular.forEach(selected.agencies, function (cur) {
          // Prevent duplicates from being saved.
          if (_validObject(cur, 'Agency')) {
            vm.user.PermissionContexts.push({
              dataObjectClass: 'Agency',
              dataObjectId: cur.id,
              permissionTypes: '|VIEW|',
              userId: vm.user.id
            });
          }
        });
        angular.forEach(selected.advertisers, function (cur) {
          // Prevent duplicates from being saved.
          if (_validObject(cur, 'Advertiser')) {
            vm.user.PermissionContexts.push({
              dataObjectClass: 'Advertiser',
              dataObjectId: cur.id,
              permissionTypes: '|VIEW|',
              userId: vm.user.id
            });
          }
        });
        angular.forEach(vm.user.PermissionContexts, function (permContext, index) {
          if (permContext.dataObjectClass === 'Agency' && permContext.dataObjectId !== 0) {
            var tempAgencyArray = selected.agencies.filter(function (agency) {
              return agency.id === permContext.dataObjectId;
            });

            if (tempAgencyArray.length === 0) {
              delete vm.user.PermissionContexts[index];
            }
          } else if (permContext.dataObjectClass === 'Advertiser' && permContext.dataObjectId !== 0) {
            var tempAdvArray = selected.advertisers.filter(function (advertiser) {
              return advertiser.id === permContext.dataObjectId;
            });

            if (tempAdvArray.length === 0) {
              delete vm.user.PermissionContexts[index];
            }
          }
        });
        vm.user.PermissionContexts = vm.user.PermissionContexts.filter(function (permContext) {
          return permContext !== null;
        });
      }, function cancelled() {// User cancelled the dialog
      });
    }

    function openPasswordDialog() {
      $mdDialog.show({
        controller: 'ChangePasswordDialogController',
        controllerAs: 'vm',
        template: require('../../common/navigation/changePasswordDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true
      });
    }

    function resetPassword() {
      if (vm.user.emailAddress === null) {
        return;
      }

      vm.passwordResetResource.email = vm.user.emailAddress;
      vm.passwordResetResource.$resetPassword({}, function () {
        /*SUCCESS*/
        NotificationService.showNotificationToast('Password Reset Initiated');
      }, function (err) {
        /*FAILURE*/
        switch (err.status) {
          case 401:
            NotificationService.showNotificationToast('Unable to find user information for email provided', err.status);
            break;

          default:
            NotificationService.showNotificationToast('Failed to reach server', err.status);
        }
      });
    }

    function selectAllColumnValues(columnName) {
      $scope.userDetailForm.$setDirty();

      switch (columnName) {
        case 'trafficDelivery':
          vm.user.Contacts.forEach(function (element) {
            element.notifyWhenDeliveryReceived = vm.trafficDelivery;

            if (vm.trafficDelivery === true) {
              element.notifyWhenDeliveryCompletionOnlyWithTraffic = false;
              vm.trafficOnly = false;
            }
          });
          break;

        case 'review':
          vm.user.Contacts.forEach(function (element) {
            element.notifyWhenOrderItemReadyForTrafficReview = vm.review;
          });
          break;

        case 'delivery':
          vm.user.Contacts.forEach(function (element) {
            element.notifyWhenOrderItemAvailableForDownload = vm.delivery;
          });
          break;

        case 'promo':
          vm.user.Contacts.forEach(function (element) {
            element.notifyWhenPromoOrderItemAvailableForDownload = vm.promo;
          });
          break;

        case 'trafficOnly':
          vm.user.Contacts.forEach(function (element) {
            element.notifyWhenDeliveryCompletionOnlyWithTraffic = vm.trafficOnly;

            if (vm.trafficOnly === true) {
              element.notifyWhenDeliveryReceived = false;
              vm.trafficDelivery = false;
            }
          });
          break;

        default:
          break;
      }
    }

    function showAdminRoles() {
      return vm.adminFlag;
    }

    function showChangePassword() {
      if (vm.user.userName == vm.currentUser) {
        vm.showChangeButton = true;
      } else {
        vm.showChangeButton = false;
      }
    }

    function toggleUserNotifications(type) {
      $scope.userDetailForm.$setDirty();
      angular.forEach(vm.notificationEvents, function (cur) {
        cur[type] = vm.notifications[type];
      });
      vm.userNotificationGridOptions.data.map(function (item) {
        return item[type] = vm.notifications[type];
      });
    }

    function isDestContact() {
      if (vm.userRoles.receiverRoles) {
        for (var i = 0; i < vm.userRoles.receiverRoles.length; i++) {
          if (vm.userRoles.receiverRoles[i].roleName === 'Destination Contact' && vm.userRoles.receiverRoles[i].selected) {
            return true;
          }
        }
      }

      return false;
    }

    function preventOther(row, changed, other) {
      if (row[changed] === true) {
        row[other] = false;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _removeAllDestNotifications() {
      for (var i = 0; i < vm.user.Contacts.length; i++) {
        vm.user.Contacts.splice(i, 1);
      }
    }

    function _checkCurrentRoles(id) {
      if (vm.user.securityRoles) {
        for (var i = 0; i < vm.user.securityRoles.length; i++) {
          if (id == vm.user.securityRoles[i].id) {
            return true;
          }
        }
      }

      return false;
    }

    function _removeRole(id) {
      for (var i = 0; i < vm.user.securityRoles.length; i++) {
        if (vm.user.securityRoles[i].id === id) {
          vm.user.securityRoles.splice(i, 1);
        }
      }
    }

    function _saveUserRoles() {
      if (isNewUser()) {
        vm.user.securityRoles = [];
      }

      angular.forEach(vm.userRoles.providerRoles, function (element) {
        if (element.selected) {
          vm.user.securityRoles.push(element);
        } else if (!element.selected && _checkCurrentRoles(element.id)) {
          _removeRole(element.id);
        }
      });
      angular.forEach(vm.userRoles.receiverRoles, function (element) {
        if (element.selected) {
          vm.user.securityRoles.push(element);
        } else if (!element.selected && _checkCurrentRoles(element.id)) {
          _removeRole(element.id);
        }
      });
      angular.forEach(vm.userRoles.vendorRoles, function (element) {
        if (element.selected) {
          vm.user.securityRoles.push(element);
        } else if (!element.selected && _checkCurrentRoles(element.id)) {
          _removeRole(element.id);
        }
      });
      var cancelOrder = vm.userRoles.cancelOrderRole;

      if (cancelOrder) {
        if (cancelOrder.selected) {
          vm.user.securityRoles.push(cancelOrder);
        } else if (!cancelOrder.selected && _checkCurrentRoles(cancelOrder.id)) {
          _removeRole(cancelOrder.id);
        }
      }

      var acceptApi = vm.userRoles.acceptApiRole;

      if (acceptApi) {
        if (acceptApi.selected) {
          vm.user.securityRoles.push(acceptApi);
        } else if (!acceptApi.selected && _checkCurrentRoles(acceptApi.id)) {
          _removeRole(acceptApi.id);
        }
      }
    }

    function _filterUserRoles() {
      var receiverRoleNames = ['Destination Contact', 'Manage Account', 'View Deliveries', 'View/Download/Traffic Review/Accept Promo', 'View Promos'];
      var providerRoleNames = ['Create/Modify/Send Orders', 'Create/Modify/Upload Spots', 'Manage Provider Account', 'View Cost Estimates and Invoices', 'View Orders', 'View Spots'];
      var vendorRoleNames = ['Manage Account', 'View Spots'];
      vm.userRoles.receiverRoles = [];
      vm.userRoles.providerRoles = [];
      vm.userRoles.vendorRoles = [];

      for (var i = 0; i < vm.allRoles.length; i++) {
        var id = vm.allRoles[i].id;
        var name = vm.allRoles[i].roleName;

        if ((vm.accountType === 'QCVENDOR' || vm.accountType === 'PRODUCTIONSERVICESVENDOR') && vendorRoleNames.indexOf(name) > -1) {
          vm.allRoles[i].selected = _checkCurrentRoles(id);
          vm.userRoles.vendorRoles.push(vm.allRoles[i]);
        } else if (receiverRoleNames.indexOf(name) > -1) {
          vm.allRoles[i].selected = _checkCurrentRoles(id);
          vm.userRoles.receiverRoles.push(vm.allRoles[i]);
        } else if (providerRoleNames.indexOf(name) > -1) {
          vm.allRoles[i].selected = _checkCurrentRoles(id);
          vm.userRoles.providerRoles.push(vm.allRoles[i]);
        } else if (name === 'Cancel Order') {
          vm.allRoles[i].selected = _checkCurrentRoles(id);
          vm.userRoles.cancelOrderRole = vm.allRoles[i];
        } else if (name === 'Access API') {
          vm.allRoles[i].selected = _checkCurrentRoles(id);
          vm.userRoles.acceptApiRole = vm.allRoles[i];
        }
      }
    }

    function resetUser() {
      $scope.userDetailForm.$setPristine();

      if ($stateParams.id == 'new') {
        vm.user = {};
        vm.repeatPassword = '';

        _filterUserRoles();

        $scope.userDetailForm.$setUntouched();
      } else {
        vm.user = angular.copy(vm.originalUser);

        _filterUserRoles();
      }

      vm.userNotificationGridOptions.data = angular.copy(vm.notificationEvents);
      vm.destinationsGridOptions.data = angular.copy(vm.user.Contacts);
    }

    function _setNotificationPreferences() {
      var newPreferences = [];
      vm.notificationEvents = angular.copy(vm.userNotificationGridOptions.data); //  If the user was not a destination contact and now is, remove the regular user notification preferences.
      //  If they were a dest contact and are now a regular user, remove Contacts.

      if (!vm.ogDestContact && vm.isDestContact()) {
        vm.notificationEvents = [];
      } else if (vm.ogDestContact && !vm.isDestContact()) {
        _removeAllDestNotifications();
      }

      angular.forEach(vm.notificationEvents, function (cur) {
        // Gotta get that ID, otherwise the world will burn when we try to associate in Sequelize.
        // Because it will see no ID and attempt to make a new row and probably fail a key or something.
        for (var i = 0; i < vm.user.NotificationPreferences.length; i++) {
          if (vm.user.NotificationPreferences[i].eventType === cur.eventType) {
            cur.id = vm.user.NotificationPreferences[i].id;
            break;
          }
        }

        newPreferences.push(cur);
      });
      vm.user.NotificationPreferences = newPreferences;
    }

    function _formatAddress() {
      //Deal with the address
      vm.user.address = '';

      if (vm.user.address1) {
        vm.user.address += vm.user.address1;
      }

      if (vm.user.address2) {
        vm.user.address += '\n' + vm.user.address2;
      }
    }

    function saveAndAdd() {
      $scope.userDetailForm.$setPristine();

      _formatAddress();

      _setNotificationPreferences();

      _saveUserRoles();

      if ($stateParams.id == 'new') {
        vm.user.emailAddress = vm.user.userName;
        UserResourceFactory.create({}, vm.user, function (user) {
          NotificationService.showNotificationToast('Successfully created user');
          $state.reload();
        }, function failure(err) {
          if (err && err.data && err.data.match(/A user with the name.*already exists in the authorities table.$/)) {
            NotificationService.showNotificationToast('User already exists with that username', err.data);
          } else {
            NotificationService.showNotificationToast('Unable to save the user with the given information', err.data);
          }
        });
      } else {
        UserResourceFactory.save({
          id: vm.user.id
        }, vm.user, function (user) {
          NotificationService.showNotificationToast('Successfully updated user');
          $state.go('userDetails', {
            id: 'new'
          });
        }, function failure(err) {
          if (err && err.data && err.data.match(/A user with the name.*already exists in the authorities table.$/)) {
            NotificationService.showNotificationToast('User already exists with that username', err.data);
          } else {
            NotificationService.showNotificationToast('Unable to save the user with the given information', err.data);
          }
        });
      }
    }

    function saveUser() {
      $scope.userDetailForm.$setPristine();

      _formatAddress();

      if ($stateParams.id == 'new' && vm.user.password == vm.repeatPassword) {
        var tempPermContexts = angular.copy(vm.user.PermissionContexts);
        vm.user.PermissionContexts = [];
        vm.user.emailAddress = vm.user.userName;
        UserResourceFactory.create({}, vm.user, function (user) {
          vm.user.id = user.data.id;
          vm.user.PermissionContexts = user.data.PermissionContexts;
          tempPermContexts.map(function (item) {
            item.userId = vm.user.id;
            vm.user.PermissionContexts.push(item);
            return item;
          });

          _setNotificationPreferences();

          _saveUserRoles();

          UserResourceFactory.save({
            id: vm.user.id
          }, vm.user, function () {
            NotificationService.showNotificationToast('Successfully created user');
            $state.go('userDetails', {
              id: vm.user.id
            });
          }, function failure(err) {
            NotificationService.showNotificationToast('Unable to save the user with the given information', err.data);
          });
        }, function failure(err) {
          if (err && err.data && err.data.match(/A user with the name.*already exists in the authorities table.$/)) {
            NotificationService.showNotificationToast('User already exists with that username', err.data);
          } else {
            NotificationService.showNotificationToast('Unable to save the user with the given information', err.data);
          }
        });
      } else {
        _setNotificationPreferences();

        _saveUserRoles();

        UserResourceFactory.save({
          id: vm.user.id
        }, vm.user, function (user) {
          NotificationService.showNotificationToast('Successfully updated user');
          $state.reload();
        }, function failure(err) {
          if (err && err.data && err.data.match(/A user with the name.*already exists in the authorities table.$/)) {
            NotificationService.showNotificationToast('User already exists with that username', err.data);
          } else {
            NotificationService.showNotificationToast('Unable to save the user with the given information', err.data);
          }
        });
      }
    }

    function _loadUserAdjacentData() {
      EnumService.getEnum('SecurityUser', 'notificationEvents').then(function (notificationEvents) {
        // We don't want users to get this email anymore, so we're murdering it here
        notificationEvents = notificationEvents.filter(function (cur) {
          return cur.name !== 'SpotImportLongDurationEvent';
        });
        vm.notificationEvents = notificationEvents.filter(function (event) {
          var listToFilterOn = [];

          switch (vm.accountType) {
            case 'PROVIDER':
              listToFilterOn = events.provider;
              break;

            case 'RECEIVER':
              listToFilterOn = events.receiver;
              break;

            case 'PRODUCTIONSERVICESVENDOR':
              listToFilterOn = events.psvendor;
              break;

            case 'QCVENDOR':
              listToFilterOn = events.qcvendor;
              break;

            default:
              return true;
          }

          return listToFilterOn.indexOf(event.name) > -1;
        }).map(function (cur) {
          var emailFlag = false;
          var growlFlag = false;

          if (vm.user.NotificationPreferences) {
            emailFlag = vm.user.NotificationPreferences.filter(function (userPref) {
              return userPref.eventType === cur.name && userPref.emailFlag === true;
            }).length > 0;
            growlFlag = vm.user.NotificationPreferences.filter(function (userPref) {
              return userPref.eventType === cur.name && userPref.growlFlag === true;
            }).length > 0;
          }

          return {
            label: cur.label,
            eventType: cur.name,
            emailFlag: emailFlag,
            growlFlag: growlFlag
          };
        });
        vm.userNotificationGridOptions.data = angular.copy(vm.notificationEvents);
      }, function (err) {
        console.log(err);
      }); // Load the agencies and advertisers after the user is loaded so we can preselect permission contexts

      AgencyResourceFactory.getAll({
        unfiltered: true
      }).subscribe(function (agencies) {
        vm.agencies = agencies.rows;
        vm.user.PermissionContexts = vm.user.PermissionContexts && vm.user.PermissionContexts.length ? vm.user.PermissionContexts : [];
        var selected = vm.user.PermissionContexts.filter(function (context) {
          return context.dataObjectClass === 'Agency';
        }).map(function (cur, index, arr) {
          return cur.dataObjectId;
        });

        for (var i = 0; i < vm.agencies.length; i++) {
          if (selected.indexOf(vm.agencies[i].id) > -1) {
            vm.agencies[i].selected = true;
          }
        }
      }, function () {
        vm.agencies = [];
      });
      AdvertiserResourceFactory.getAll({
        unfiltered: true
      }).subscribe(function (advertisers) {
        vm.advertisers = advertisers;
        vm.user.PermissionContexts = vm.user.PermissionContexts && vm.user.PermissionContexts.length ? vm.user.PermissionContexts : [];
        var selected = vm.user.PermissionContexts.filter(function (context) {
          return context.dataObjectClass === 'Advertiser';
        }).map(function (cur, index, arr) {
          return cur.dataObjectId;
        });

        for (var i = 0; i < vm.advertisers.length; i++) {
          if (selected.indexOf(vm.advertisers[i].id) > -1) {
            vm.advertisers[i].selected = true;
          }
        }
      }, function () {
        vm.advertisers = [];
      });
      SecurityRoleResourceFactory.query({}, function (roles) {
        vm.allRoles = roles;

        _filterUserRoles();
      }, function () {
        vm.allRoles = {};
      });
    }

    function _validObject(cur, objectClass) {
      for (var i = 0; i < vm.user.PermissionContexts.length; i++) {
        var temp = vm.user.PermissionContexts[i];

        if (cur.id === temp.dataObjectId && temp.dataObjectClass === objectClass) {
          return false;
        }
      }

      return true;
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($stateParams.id == 'new') {
        vm.user = {
          account: sessionData.account,
          NotificationPreferences: [],
          // We should default new users to activated, since Ripley doesn't have a way to change this value currently
          activated: true,
          enabled: true
        };
        vm.user.country = 'US';

        _loadUserAdjacentData();
      } else {
        UserResourceFactory.get({
          id: $stateParams.id
        }, function (user) {
          vm.user = user;

          if (vm.user.address) {
            var addresses = vm.user.address.split('\n');
            vm.user.address1 = addresses[0];
            vm.user.address2 = addresses.length === 2 ? addresses[1] : '';
          }

          vm.originalUser = angular.copy(user);

          for (var i = 0; i < vm.originalUser.securityRoles.length; i++) {
            var curr = vm.originalUser.securityRoles[i];

            if (curr.roleName === 'Destination Contact') {
              vm.ogDestContact = true;
            }
          }

          vm.destinationsGridOptions.data = user.Contacts;
          vm.showChangePassword();

          _loadUserAdjacentData();
        });
      }

      UserResourceFactory.getJobClassifications({}, function (classifications) {
        vm.jobClassifications = classifications.data;
      }, function () {
        vm.jobClassifications = [];
      });
      DestinationListResourceFactory.getAll({
        providerAccountId: sessionData.accountId
      }, {}, function success(destinationLists) {
        vm.destinationLists = destinationLists.data;
      }, function failure(err) {
        console.log(err);
      });
      EnumService.getEnum('SecurityUser', 'state').then(function (states) {
        vm.states = states;
      }, function () {
        vm.states = [];
      });
      EnumService.getEnum('SecurityUser', 'province').then(function (provinces) {
        vm.provinces = provinces;
      }, function () {
        vm.provinces = [];
      });
      EnumService.getEnum('SecurityUser', 'country').then(function (countries) {
        vm.countries = countries;
      }, function () {
        vm.countries = [];
      });
      $timeout(function () {
        $rootScope.userDetailForm = $scope.userDetailForm;
      });
    }
  }]).controller('ManageFiltersController', ['$state', '$mdDialog', 'agencies', 'advertisers', function ($state, $mdDialog, agencies, advertisers) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.agencies = agencies;
    vm.advertisers = advertisers;
    vm.selectedAgencies = [];
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.selectAgency = selectAgency;
    vm.selectAdvertiser = selectAdvertiser;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function save() {
      $mdDialog.hide({
        agencies: vm.agencies.filter(function (cur) {
          return cur.selected;
        }),
        advertisers: vm.advertisers.filter(function (cur) {
          return cur.selected;
        })
      });
    }

    function selectAgency(agencyId, doit) {
      if (!doit) {
        // Unselect the Agency if this was it's last selected advertiser
        var stillGood = false;
        angular.forEach(vm.advertisers, function (cur) {
          if (cur.agencyId === agencyId && cur.selected) {
            stillGood = true;
          }
        });

        if (!stillGood) {
          angular.forEach(vm.agencies, function (cur) {
            if (cur.id === agencyId) {
              cur.selected = false;
            }
          });
        }
      } else {
        angular.forEach(vm.agencies, function (cur) {
          if (cur.id === agencyId) {
            cur.selected = true;
          }
        });
      }
    }

    function selectAdvertiser(agencyId, select) {
      if (select) {
        vm.selectedAgencies.push(agencyId);
      } else {
        vm.selectedAgencies.splice(vm.selectedAgencies.indexOf(agencyId), 1);
      }

      angular.forEach(vm.advertisers, function (cur) {
        if (cur.agencyId === agencyId) {
          cur.selected = select;
        }
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      //TODO: Initialize this controller
      for (var i = 0; i < vm.agencies.length; i++) {
        if (vm.agencies[i].selected) {
          vm.selectedAgencies.push(vm.agencies[i].id);
        }
      }
    }
  }]).controller('DestinationsDialogController', ['$state', '$mdDialog', '$q', 'DestinationResourceFactory', 'selectedRow', 'contacts', function ($state, $mdDialog, $q, DestinationResourceFactory, selectedRow, contacts) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.currentSearch = '';
    vm.multiCallLetter = {};
    vm.searchResults = {};
    vm.flags = {};
    vm.isDisabled = false;
    vm.selectedRow = selectedRow;
    vm.contacts = contacts;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.clearSearchTerm = clearSearchTerm;
    vm.querySearch = querySearch;
    vm.save = save;
    vm.searchTextChange = searchTextChange;
    vm.selectedItemChange = selectedItemChange;
    vm.preventOther = preventOther;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function clearSearchTerm() {
      vm.currentSearch = '';
      vm.searchResults = '';
    }

    function querySearch() {
      var deferred = $q.defer();

      if (vm.currentSearch !== '') {
        DestinationResourceFactory.getAll({
          alias: 'like:' + vm.currentSearch,
          ripleyView: true
        }, function (destinations) {
          vm.searchResults = destinations.data.rows;

          if (vm.contacts) {
            var _loop = function _loop(i) {
              vm.searchResults = vm.searchResults.filter(function (el) {
                return el.id != vm.contacts[i].Destination.id;
              });
            };

            for (var i = 0; i < vm.contacts.length; i++) {
              _loop(i);
            }
          }

          deferred.resolve(vm.searchResults);
        });
      }

      return deferred.promise;
    }

    function save() {
      $mdDialog.hide({
        multiCallLetter: vm.multiCallLetter,
        flags: vm.flags
      });
    }

    function searchTextChange(text) {
      vm.currentSearch = text;
    }

    function selectedItemChange(item) {
      vm.multiCallLetter = item;
    }

    function preventOther(changed, other) {
      if (vm.flags[changed] === true) {
        vm.flags[other] = false;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if (vm.selectedRow) {
        vm.isDisabled = true;
        vm.selectedItem = vm.selectedRow.Destination.alias;
        vm.multiCallLetter.id = vm.selectedRow.Destination.id;
        vm.flags.notifyWhenDeliveryReceived = vm.selectedRow.notifyWhenDeliveryReceived;
        vm.flags.notifyWhenDeliveryCompletionOnlyWithTraffic = vm.selectedRow.notifyWhenDeliveryCompletionOnlyWithTraffic;
        vm.flags.notifyWhenOrderItemReadyForTrafficReview = vm.selectedRow.notifyWhenOrderItemReadyForTrafficReview;
        vm.flags.notifyWhenOrderItemAvailableForDownload = vm.selectedRow.notifyWhenOrderItemAvailableForDownload;
        vm.flags.notifyWhenPromoOrderItemAvailableForDownload = vm.selectedRow.notifyWhenPromoOrderItemAvailableForDownload;
      }
    }
  }]);
})();